import { AbstractResource } from '../AbstractResource'

type McPasswordCallbackCreateProps = {
  login: string
  password: string
}

class McPasswordCallbackResource extends AbstractResource {
  _endpointUrl = '/auth/mc_password/callback'

  create({ login, password }: McPasswordCallbackCreateProps) {
    return this.fetchResource(this._endpointUrl, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ login, password }),
    })
  }
}

export { McPasswordCallbackResource }
