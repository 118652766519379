import ready from 'document-ready-promise'
import { renderComponent } from '../mc-ui/utils/renderComponent'

import { Login, ForgotPassword } from '../mc-app/user-login'


ready()
  .then(() => {
    renderComponent(Login, 'login-root')
    renderComponent(ForgotPassword, 'forgot-password-root')
  })
  .catch((err) => {
    console.error('Unable to render:', err)
  })
