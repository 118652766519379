import { Flex, Text } from '@instructure/ui'

export const Header = () => {
  return (
    <Flex margin="none none medium none">
      <Flex.Item shouldGrow>
        <Text size="x-large" weight="bold">Welcome to Mastery Connect</Text>
      </Flex.Item>
    </Flex>
  )
}
