import { AbstractResource } from '../AbstractResource'

type McSiteAdminPasswordCallbackCreateProps = {
  login: string
  password: string
}

// This is very similar to McPasswordCallbackResource, but it's used for Site Admins (AKA AdminUsers).
// I know the endpoint could just be passed in, but I prefer to keep a clean separation so we can
// more easily yank this out later in favor of an Okta integration.
class McSiteAdminPasswordCallbackResource extends AbstractResource {
  _endpointUrl = '/auth/mc_site_admin_password/callback'

  create({ login, password }: McSiteAdminPasswordCallbackCreateProps) {
    return this.fetchResource(this._endpointUrl, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ login, password }),
    })
  }
}

export { McSiteAdminPasswordCallbackResource }
