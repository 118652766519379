import { useState } from 'react'
import {
  Tag,
  Flex,
  Text,
  Link,
  View,
  Button,
  Spinner,
  TextInput,
  IconButton,
  IconEyeLine,
  FormFieldGroup
} from '@instructure/ui'
import { selectorAttrs } from '../../../mc-ui'
import { IconEyeHiddenLine } from '../../../mc-icons'

const FORGOT_PASSWORD_URL = '/auth/forgot_passwords'

type FormProps = {
  credentials: { login: string, password: string },
  ldapGuid: string,
  attempting: boolean,
  onCredentialsChange: (credentials: { login: string, password: string }) => void,
  onLdapCancel: () => void,
  onSubmit: () => void,
  usernameRef: (inputElement: HTMLInputElement | null) => void
}

export const Form = (({
  credentials: { login, password },
  ldapGuid,
  attempting,
  onCredentialsChange,
  onLdapCancel,
  onSubmit,
  usernameRef
}: FormProps) => {
  const interactionMode = attempting ? 'disabled' : 'enabled'
  const [showPassword, setShowPassword] = useState(false)

  return (
    <View as="form" onSubmit={(e) => { e.preventDefault(); onSubmit() }}>
      <FormFieldGroup description="" layout="stacked" rowSpacing="medium">
        <TextInput
          renderLabel={
            <View>
              <Text>
                {ldapGuid ? 'Username' : 'Email Address or Username'}
              </Text>
              <Text color='danger'>{' * '}</Text>
            </View>
          }
          value={login}
          isRequired={true}
          onChange={(_evt, value) => onCredentialsChange({ login: value, password })}
          interaction={interactionMode}
          renderBeforeInput={
            ldapGuid &&
            <Tag text={ldapGuid} dismissible onClick={onLdapCancel} />
          }
          inputRef={usernameRef}
          { ...selectorAttrs('login-email-input') }
        />

        <TextInput
          type={showPassword ? "text" : "password"}
          renderLabel={
            <View>
              <Text>Password</Text>
              <Text color='danger'>{' * '}</Text>
            </View>
          }
          value={password}
          isRequired={true}
          onChange={(_evt, value) => onCredentialsChange({ login, password: value})}
          interaction={interactionMode}
          renderAfterInput={(
            <IconButton
              shape="circle"
              size="small"
              withBorder={false}
              withBackground={false}
              screenReaderLabel="Show password"
              onClick={() => setShowPassword(!showPassword)}
              renderIcon={showPassword ? IconEyeHiddenLine : IconEyeLine}
              { ...selectorAttrs('login-show-password-button') }
            />
          )}
          { ...selectorAttrs('login-email-password') }
        />

        <Button
          type="submit"
          display="block"
          color="primary"
          textAlign="center"
          interaction={interactionMode}
          { ...selectorAttrs('login-submit-button') }
        >
          { attempting && <Spinner renderTitle="Checking..." size="x-small" /> }{' Log In '}
        </Button>

        <Flex alignItems='center' justifyItems='center'>
          <Link
            href={FORGOT_PASSWORD_URL}
            isWithinText={false}
            { ...selectorAttrs('login-forgot-password-button') }
          >
            Forgot Password?
          </Link>
        </Flex>
      </FormFieldGroup>
    </View>
  )
})
