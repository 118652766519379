import { ReactNode } from 'react'
import { View, Flex } from '@instructure/ui'
import { Global } from '@instructure/emotion'
import { useMedia } from 'react-use'
import { IconInstructureLogo } from '../../../mc-icons'
import { BaseNavHeader } from '../../components/NavHeader'
import { breakpoints, mqRange } from '../../../mc-ui'

import background from './images/background.jpg'

type Props = { children: ReactNode }

const CssOverrides = () => {
  return (
    <Global
      styles={{
        html: {
          height: '100%',
          overflow: 'hidden',
        },
        body: {
          height: '100%',
          margin: 0
        }
      }}
    />
  )
}

const AuthLayout = ({ children }: Props) => {
  const isSmall = useMedia(`(max-width: ${breakpoints.medium}px)`)
  const isMedium = useMedia(`(max-width: ${breakpoints.large}px)`)
  const isLarge = useMedia(`(min-width: ${breakpoints.large}px)`)

  return (
    <View
      as="div"
      position="relative"
      overflowX="hidden"
      overflowY={isSmall || isMedium ? 'visible' : 'hidden'}
      height={'100vh'}
      background={'transparent'}
    >
      <CssOverrides />
      <View
        as="div"
        position="fixed"
        stacking="deepest"
        maxWidth="100%"
        minHeight="100%"
        minWidth={`${breakpoints.large}px`}
        width="50%"
        css={{
          right: 0,
          background: `url(${background}) 30% 100% / cover no-repeat`,
          [mqRange('small')]: {
            display: 'none',
          },
        }}
      />
      <BaseNavHeader navItems={null} activeIds={null} noBorderBottom />
      <View
        as="div"
        position="relative"
        padding={isSmall ? 'none' : isMedium ? 'xx-large' : 'none'}
        width={isLarge ? '50%' : '100%'}
        height='100%'
        themeOverride={() => ({
          paddingXxLarge: '5.125rem'
        })}
      >
        <Flex
          as="div"
          direction='column'
          height='100%'
        >
          <View
            as="div"
            position="relative"
            width="100%"
            display="flex"
            background="primary"
            borderRadius={isSmall ? 'none' : isMedium ? 'medium' : 'none'}
            css={{flex: 1}}
            stacking="above"
            padding={isSmall ? 'none none x-large none' : 'none'}
          >
            <Flex
              as="div"
              width="100%"
              padding={isSmall ? 'medium large' : 'xx-large'}
              justifyItems='center'
              alignItems='start'
            >
              {children}
            </Flex>
          </View>
          {
            isSmall ? null : isMedium ? (
              <View as='div' padding='large none medium'>
                <IconInstructureLogo
                  width="100%"
                  css={{
                    color: 'white',
                    opacity: '72%' // opacity as designed
                  }}
                />
              </View>
            ) : (
              <View
                position="fixed"
                insetBlockEnd={'4.5rem'}
                insetInlineEnd={'5rem'}
              >
                <IconInstructureLogo
                  width="100%"
                  css={{
                    color: 'white',
                    opacity: '72%' // opacity as designed
                  }}
                />
              </View>
            )
          }
        </Flex>
      </View>
    </View>
  )
}

export const generateStyle = () => {
  return {
    bgImage: {
      right: 0,
      background: `url(${background}) 30% 100% / cover no-repeat`,
      [mqRange('small')]: {
        display: 'none',
      },
    },
    instructureLogo: {
      color: 'white',
      opacity: '72%' // opacity as designed
    }
  }
}

export default AuthLayout
