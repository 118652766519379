import {
  Button,
  View,
  Flex,
  Text
} from '@instructure/ui'

import { IconGoogle, IconClever, IconClasslink } from '../../../mc-icons'
import { selectorAttrs } from '../../../mc-ui'

const GOOGLE_AUTH_URL = '/auth/google_user_auth'
const CLEVER_AUTH_URL = '/auth/clever_user_auth'
const CLASS_LINK_AUTH_URL = '/auth/class_link_user_auth'

export const ThirdPartyLogins = ({ classlinkEnabled }: { classlinkEnabled: boolean }) => {
  return (
    <Flex direction='column'>
      <View margin="x-small 0">
        <Button
          display="block"
          href={GOOGLE_AUTH_URL}
          { ...selectorAttrs('login-google-button') }
        >
          <View position='absolute' insetInlineStart='0.75rem'>
            <IconGoogle width="18px" />
          </View>
          <Text>Log in with Google</Text>
        </Button>
      </View>
      <View margin="x-small 0">
        <Button
          display="block"
          href={CLEVER_AUTH_URL}
          { ...selectorAttrs('login-cleaver-button') }
        >
          <View position='absolute' insetInlineStart='0.75rem'>
            <IconClever width="20px" />
          </View>
          <Text>Log in with Clever</Text>
        </Button>
      </View>
      {
        classlinkEnabled && (<View margin="x-small 0">
          <Button
            display="block"
            href={CLASS_LINK_AUTH_URL}
            { ...selectorAttrs('login-classlink-button') }
          >
            <View position='absolute' insetInlineStart='0.75rem'>
              <IconClasslink width="26px" />
            </View>
            <Text>Log in with Classlink</Text>
          </Button>
        </View>
        )
      }
    </Flex>
  )
}
