import { useState, useRef } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { McLdapCallbackResource, McPasswordCallbackResource, McSiteAdminPasswordCallbackResource, McLdapLookupResource, LdapCredentialTokenDTO } from '../../../mc-resources/auth'
import { Alert, View } from '@instructure/ui'
import { Header } from './Header'
import { Form } from './Form'
import { ThirdPartyLogins } from './ThirdPartyLogins'
import { Footer } from './Footer'
import AuthLayout from '../components/AuthLayout'

const COOKIE_NAME = 'ldap_auth'

// This should match ActionDispatch::Cookies::CookieJar::DOMAIN_REGEXP as it is
// what is used for setting `domain: all` in a cookie in Rails, which is what
// we're doing to set the cookie.
const DOMAIN_REGEX = /[^.]*\.([^.]*|..\...|...\...)$/

// For now Site Admin users log in with a username and password on the same form,
// but it hits a different endpoint on the backend to keep the concerns separate.
// Eventually this should probably go away in favor of Okta auth via OIDC or SAML.
const ADMIN_USERNAME_REGEX = /@admin$/

interface LoginProps {
  flashErrors?: string[],
  classlinkEnabled: boolean
}

// The domain here needs to match what's already set by Rails in the
// cookie. Rails uses `domain: :all`, which would translate into
// `.masteryconnect.com` for instance. This is needed because when it's set,
// the full domain would be school-name.masteryconnect.com, because that's
// how our LDAP auth works.
const { hostname } = window.location
const domain = hostname.match(DOMAIN_REGEX)[0]

export const Login = ({
  flashErrors = [],
  classlinkEnabled
}: LoginProps) => {
  const [{ ldap_auth }, _setCookie , removeCookie] = useCookies([COOKIE_NAME])
  const [attempting, setAttempting] = useState(false)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessages, setErrorMessages] = useState([...flashErrors])

  const { ldapId, guid } = ldap_auth || {}
  const usernameRef = useRef<HTMLInputElement>()

  const setCredentials = ({ login, password }) => {
    setLogin(login)
    setPassword(password)
    setErrorMessages([])

    if (login[login.length - 1] === '/') {
      lookupLdapDomain(login)
    }
  }

  const lookupLdapDomain = (login: string) => {
    const ldapLookupResource = McLdapLookupResource.createInstance()

    const parsedLogin = login.replace(/[^a-zA-Z0-9]/g, '')

    const focusOnUsernameInput = () => {
      // Focus on the username input after the LDAP domain is set.
      // Set a timeout to ensure the input is rendered before focusing.
      setTimeout(() => { usernameRef.current.focus() }, 100)
    }

    ldapLookupResource.list({ ldapGuid: parsedLogin }).then((resp) => {
      if (resp.id) {
        setLogin('')
        _setCookie(COOKIE_NAME, { guid: resp.title, ldapId: resp.id }, { domain: `.${domain}` })
        focusOnUsernameInput()
      }
    })
  }

  const cancelLdapAuth = () => {
    removeCookie(COOKIE_NAME, { domain: `.${domain}` })
  }

  const passwordAuth = () => {
    // This is all a bit legacy and eventually Okta auth or the new Identity Service should reder this unnecessary.
    // But the way the Site Admin (AKA AdminUser) login process has always worked is that a @admin suffix is
    // appended to the AdminUser#username. The backend endpoint and logit used to me merged together, but we're
    // separating them here so that we can hopefully soon pull out all the Site Admin specific stuff.
    const adminUsername = !!login.match(ADMIN_USERNAME_REGEX)
    const passwordResourceClass = (adminUsername ? McSiteAdminPasswordCallbackResource : McPasswordCallbackResource)
    const passwordResource = passwordResourceClass.createInstance()

    passwordResource.create({ login, password }).then(
      handleSuccess
    ).catch(
      handleFailure
    )
  }

  const ldapAuth = () => {
    const ldapResource = McLdapCallbackResource.createInstance()

    const ldapCredentials: LdapCredentialTokenDTO = {
      login,
      password,
      ldapId
    }

    ldapResource.create(ldapCredentials).then(
      handleSuccess
    ).catch(
      handleFailure
    )
  }

  const handleSuccess = (resp) => {
    if (resp.success === true) {
      goToApp(resp)
    } else {
      handleFailure(resp)
    }
  }

  const handleFailure = (error) => {
    if(error.response?.status === 401) {
      error.response.json().then(({ error }) => resetForm(error))
    } else {
      resetForm(error.message || error.error || 'An error occurred. Please try again.')
    }
  }

  const resetForm = (msg) => {
    setPassword('')
    setAttempting(false)
    setErrorMessages(errorMessages.concat(msg))
  }

  const goToApp = ({ location }) => { window.location = location || '/' }

  const submit = () => {
    setAttempting(true)

    ldap_auth ? ldapAuth() : passwordAuth()
  }

  const AlertMessage = ({ message }) => {
    return (
      <Alert
        variant="error"
        renderCloseButtonLabel="Close"
        margin="small none"
        timeout={5000}
        onDismiss={() => setErrorMessages([])}
      >
        {message}
      </Alert>
    )
  }

  return (
    <CookiesProvider>
      <AuthLayout>
        <View
          maxWidth='32.5rem'
          minWidth='17.5rem'
          width='32.5rem'
        >
          <Header />
          {errorMessages.map((errorMessage, index) => (
            <AlertMessage message={errorMessage} key={index} />
          ))}
          <ThirdPartyLogins classlinkEnabled= { classlinkEnabled } />
          <View as='div' margin='large none large'>
            <View
              as={'hr'}
              borderWidth={'small 0 0 0'}
              width={'100%'}
              margin={'0 0 medium 0'}
              role={'separator'}
            />
          </View>
          <Form
            credentials={{ login, password }}
            ldapGuid={guid}
            attempting={attempting}
            onCredentialsChange={setCredentials}
            onLdapCancel={cancelLdapAuth}
            onSubmit={submit}
            usernameRef={(el) => (usernameRef.current = el)}
          />
          <View as='div' margin='medium none'>
            <Footer />
          </View>
        </View>
      </AuthLayout>
    </CookiesProvider>
  )
}
