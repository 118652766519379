import { AbstractResource } from '../AbstractResource'

type McLdapCallbackCreateProps =  {
  ldapGuid: string
}

class McLdapLookupResource extends AbstractResource {
  _endpointUrl = '/ldap_lookup'
  _snakeCaseKeys = true

  list({ ldapGuid }: McLdapCallbackCreateProps) {
    return super.list({ ldapGuid })
  }
}

export { McLdapLookupResource }
