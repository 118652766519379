import { Flex, Link, Text } from '@instructure/ui'
import { selectorAttrs } from '../../../mc-ui'

const TERMS_OF_SERVICE_URL = '/acceptable_use'
const PRIVACY_POLICY_URL = '/privacy'

export const Footer = () => {
  return (
    <>
      <Flex as="div" justifyItems="center">
        <Flex.Item padding="xx-small">
          <Text size="small">
            <Link
              href={PRIVACY_POLICY_URL}
              isWithinText={false}
              themeOverride={(_, { colors }) => ({
                color: colors.ash,
                hoverColor: colors.ash
              })}
              { ...selectorAttrs('login-privacy-button') }
            >
              Privacy Policy
            </Link>
          </Text>
        </Flex.Item>
        <Flex.Item padding="xxx-small">
          <Text size="small" color='secondary'> | </Text>
        </Flex.Item>
        <Flex.Item padding="xx-small">
          <Text size="small">
            <Link
              href={TERMS_OF_SERVICE_URL}
              isWithinText={false}
              themeOverride={(_, { colors }) => ({
                color: colors.ash,
                hoverColor: colors.ash
              })}
              { ...selectorAttrs('login-policy-button') }
            >
              Acceptable Use Policy
            </Link>
          </Text>
        </Flex.Item>
      </Flex>
    </>
  )
}
