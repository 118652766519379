import { AbstractResource } from '../AbstractResource'
import { ResourceRequestMetadata } from '../types'

type LdapCredentialTokenDTO = {
  login: string
  password: string
  ldapId: number
}

class McLdapCallbackResource extends AbstractResource {
  _endpointUrl = '/auth/mc_ldap/callback'
  _snakeCaseKeys = true

  create(data: LdapCredentialTokenDTO) {
    const metadata: ResourceRequestMetadata = {}
    metadata.responseType = 'json'
    return super.create(
      data,
      metadata
    )
  }
}

export type { LdapCredentialTokenDTO }
export { McLdapCallbackResource }
