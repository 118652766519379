import { useState } from 'react'
import {
  Flex,
  View,
  Text,
  Alert,
  Button,
  Spinner,
  TextInput,
  FormFieldGroup,
  IconEmailSolid
} from '@instructure/ui'
import { selectorAttrs } from '../../../mc-ui'
import { McRequestForgotPasswordResource } from '../../../mc-resources/auth'

export const Form = () => {
  const [attempting, setAttempting] = useState(false)
  const [username, setUsername] = useState('')
  const [errorMessages, setErrorMessages] = useState([])
  const [emailSent, setEmailSent] = useState(false)

  const interactionMode = attempting ? 'disabled' : 'enabled'

  const handleSubmit = () => {
    setAttempting(true)

    const requestForgotPasswordResource = McRequestForgotPasswordResource.createInstance()

    requestForgotPasswordResource.create({ username: username }).then(
      handleSuccess
    ).catch(
      handleFailure
    )
  }

  const handleSuccess = (resp) => {
    if (resp.success === true) {
      setEmailSent(true)
    } else {
      handleFailure(resp)
    }
  }

  const handleFailure = (error) => {
    const msg = error.message || error.error || 'An error occurred. Please try again.'

    setUsername('')
    setAttempting(false)
    setErrorMessages(errorMessages.concat(msg))
  }

  return (
    <View
      as="div"
      maxWidth='32.5rem'
      minWidth='23.75rem'
      width='32.5rem'
    >
      <View as="div" margin="none none small">
        { emailSent ? (
          <Flex direction="row" alignItems="center">
            <View margin="0 small 0 0">
              <IconEmailSolid color="brand" width="1.5rem" height="1.5rem" />
            </View>
            <Text size="large" weight="bold">
              Reset email has been sent!
            </Text>
          </Flex>
        ) : (
          <Text size="large" weight="bold">
            Forgot Password?
          </Text>
        )}
      </View>
      {errorMessages.map((errorMessage, index) => (
        <Alert
          variant="error"
          renderCloseButtonLabel="Close"
          margin="small 0"
          timeout={5000}
          key={index}
        >
          {errorMessage}
        </Alert>
      ))}
      <View as="div" margin="none none small">
        <Text
          as="div"
          lineHeight="double"
          themeOverride={({ lineHeightDouble: '1.5rem'})}
        >
          {
            emailSent ? (
              'An email has been sent to you that contains a link to reset your password. Please follow the instructions contained in the email to login to Instructure.'
            ) : (
              'Having trouble remembering your password? Enter your email address in the field below, and click Send Request.'
            )
          }
        </Text>
      </View>
      <View as="div" margin="none none small">
        <View as="form" onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
          <FormFieldGroup description="" layout="stacked" rowSpacing="medium">
            { !emailSent && (
              <TextInput
                renderLabel={
                  <Text>Email Address or Username</Text>
                }
                value={username}
                isRequired={true}
                onChange={(_evt, value) => setUsername(value)}
                interaction={interactionMode}
                { ...selectorAttrs('forgot-password-email-input') }
              />
            )}
            <Flex direction="row" justifyItems='end'>
              <Button
                type="button"
                color="secondary"
                textAlign="center"
                href={'/auth'}
                margin="none small"
                { ...selectorAttrs('forgot-password-back-to-login-button') }
              >
                Back to Login
              </Button>
              { !emailSent && (
                <Button
                  type="submit"
                  color="primary"
                  textAlign="center"
                  interaction={interactionMode}
                  { ...selectorAttrs('forgot-password-submit-button') }
                >
                  { attempting && <Spinner renderTitle="Checking..." size="x-small" /> }{' Send Request '}
                </Button>
              )}
            </Flex>
          </FormFieldGroup>
        </View>
      </View>
    </View>
  )
}